import * as React from "react";
import PageLayout from "../PageLayout";
import SectionLayout from "../SectionLayout";
import {Helmet} from "react-helmet";
import {Link} from "gatsby";

const PageNotFound = () => {
    return (
        <PageLayout
            noButton
            navSize={"h-96"}
            title={"Page Not Found"}
            subtitle={"404"}
            titleStyles={"xl:mt-12 lg:mt-20 md:mt-20 sm:mt-20 mt-16"}
        >
            <Helmet>
                <title>Page Not Found | EPG Solutions</title>
            </Helmet>
            <div className={"bg-epg-yellow h-5"}/>
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={"py-6 md:py-10 lg:py-14 "}>
                <p className={"font-exo2 leading-7 lg:leading-8"}>Oops! Looks like we couldn’t find this page, or it doesn’t exist.
                    <br/>
                    Please double check the spelling of the URL, or navigate to the homepage using the button below.</p>
                <Link to={"/"}>
                    <button
                        className={
                            "mt-8 justify-start focus:outline-none bg-epg-yellow hover:bg-epg-white px-6 h-12 lg:h-16  font-exo2 text-xl font-bold rounded-md self-start"
                        }
                        type="button"
                    >
                        Back to home
                    </button>
                </Link>
                </div>
            </SectionLayout>
        </PageLayout>
    );
};

export default PageNotFound;
